import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Mint from 'mint-ui'
import 'mint-ui/lib/style.css'
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'ee07ccf90b01296762bcf8b79b781215',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'Geocoder'], //plugin所要用到的模块功能，按需添加
  v: '1.4.4',
});
Vue.use(Mint);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
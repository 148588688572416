import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('views/home/home')
const Pull = () => import('views/pull/pull')
const Categery = () => import('views/categery/categery')
const Punch = () => import('views/punch/punch')

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: "",
        redirect: '/pull'
    },
    {
        path: '/home',
        component: Home,
        children: [{
                path: '',
                redirect: '/punch'
            },
            {
                path: '/punch',
                component: Punch,
                meta: {
                    title: '打卡'
                }
            },
            {
                path: '/categery',
                component: Categery,
                meta: {
                    title: '记录'
                }
            }
        ]
    },
    {
        path: '/pull',
        component: Pull,
        meta: {
            title: '医学进修签到'
        }
    },
]

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
})
export default router
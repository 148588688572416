<template>
  <div>
    	<!-- <keep-alive exclude="Dashboard"> -->
        <router-view />
      <!-- </keep-alive> -->
    <mt-tabbar v-model="selected" class="tabbarBottom">
      <mt-tab-item id="punch">
        <img slot="icon" :src="img1" />
        打卡
      </mt-tab-item>
      <mt-tab-item id="categery">
        <img slot="icon" :src="img2" />
        记录
      </mt-tab-item>
    </mt-tabbar>
  </div>
</template>

<script>
import { toGet, toPost } from "utils/request";
export default {
  name: "home",
  components: {},
  data() {
    return {
      selected: "punch",
      img1: require("assets/images/punch_active.svg"),
      img2: require("assets/images/record.svg"),
    };
  },
  watch: {
    selected: {
      handler() {
        if (this.selected == "punch") {
          this.$router.push("/punch");
          this.img1 = require("assets/images/punch_active.svg");
        } else {
          this.img1 = require("assets/images/punch.svg");
        }
        if (this.selected == "categery") {
          this.$router.push("/categery");
          this.img2 = require("assets/images/record_active.svg");
        }else{
          this.img2 = require("assets/images/record.svg");
        }
      },
    },
  },
};
</script>

<style scoped>
.tabbarBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
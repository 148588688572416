import axios from 'axios'
import * as Configs from '../configs/BaseConfigs';
import { MessageBox } from 'mint-ui';


axios.defaults.timeout = 60000;
axios.defaults.baseURL = Configs.serverUrl;

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function toGet(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(response => {
                if (response.JSON.parse(d).Error === '') {
                    resolve(response.JSON.parse(d).Data);
                } else {
                    MessageBox('提示', response.JSON.parse(d).Error);
                }
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function toPost(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(response => {
                if (JSON.parse(response.data.d).Error === '') {
                    resolve(JSON.parse(response.data.d).Data);
                } else {
                    MessageBox('提示', JSON.parse(response.data.d).Error);
                    reject(response)
                }
            });
    });
}
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Home from "views/home/home";
export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style>
</style>
